<template>
  <div class="index">
    <div class="content">


      <div class="titles" style="border-top: 7px solid #f2f4f8;">欢迎语创建</div>
      <div>
        <van-cell @click="$refs.employee.$show(serviceList,'serviceList')" required title="员工" is-link value="请选择成员">
          <div class="user-list" v-if="serviceList.length > 0">
            <div v-for="(item,index) in serviceList" :key="index">
              <img :src="item.avatar"/>
              {{ item.name }}
            </div>
          </div>
          <div v-else>请选择成员</div>
        </van-cell>
      </div>

      <div class="">
        <van-cell title="欢迎语设置">
          <template #title>
            <span style="">欢迎语</span>
            <span style="    color: #294ba3;
    margin-left: 10px;">
          <img style="width: 15px;height: 15px;vertical-align: middle;margin-top: -3px;"
               src="../../assets/images/preview.svg"/>
          </span>
          </template>
          <template #value>
            <span style="color: #444;">{{ greeting }}</span>
          </template>
        </van-cell>

        <div class="content">
          <span style="color: #999;font-size: 12px;    margin: 0 14px;
    display: block;">*设置渠道欢迎语后将会覆盖原有的欢迎语，扫码添加的客户将会回复设置的渠道欢迎语</span>

          <div style=" padding: 10px 12px;">

            <van-field
                v-model="fixedMessage"
                rows="2"
                :autosize="{maxHeight:300,minHeight:150}"
                type="textarea"
                maxlength="1000"
                placeholder="请输入"
                show-word-limit
                style="background: #f8f9fb;"
            >
              <template #button>
                <div style="display: none" class="button">
                  插入客户昵称
                </div>
              </template>
            </van-field>

            <div class="file-list">
              <div v-for="(item,index) in fixedFileList" :key="index" class="item">
                <div>
                  <a-icon @click="removeFile(index)" type="minus-circle"/>
                  <span>{{ item.type }}{{ item.title }}</span>
                </div>
                <a-icon type="form" @click="editFile(item,index)"/>
              </div>
            </div>

            <van-popover
                class="prop"
                v-model="showPopover"
                trigger="click"
                placement="top-start"
            >
              <ul class="options">
                <li @click="choseImg(false)" class="clickable">
                  <img
                      src="../../assets/images/multiSendabPicture.png">
                  <span>图片</span>
                </li>
                <li @click="choseUrl(false)" class="clickable"><img
                    src="../../assets/images/multiSendableLink.png">
                  <span>链接</span></li>
                <li @click="choseApplet(false)" class="clickable"><img
                    src="../../assets/images/multiSendableWxapp.png">
                  <span>小程序</span></li>
                <li @click="choseLuckDraw(false)" class="clickable"><img
                    src="../../assets/images/multisendablechoujiang.png">
                  <span>抽奖活动</span></li>
              </ul>
              <template #reference>
                <div class="init-trigger fs14">
                  <div class="icon">
                    <img src="../../assets/images/plus-new.svg"
                         class="icon-plus-new svg-icon"></div>
                  从素材中心选择
                </div>
              </template>
            </van-popover>

          </div>

          <van-cell title="分时段欢迎语">

            <div class="openOr" style="display: flex;align-items: center;justify-content: end;">
              <van-switch v-model="timeInterval" size="16px"/>
              <span>{{ timeInterval ? '已开启' : '已关闭' }}</span>
            </div>
          </van-cell>

          <div v-if="timeInterval">
            <div v-for="(item,index) in timeIntervalList" :key="index">
              <div class="header">
                <div class="left">
                  <span class="title">分时段欢迎语{{ index + 1 }}</span>
                  <img style="width: 15px;
    height: 15px;" src="../../assets/images/preview.svg"
                       class="preview-icon svg-icon">
                  <span class="preview"></span>
                </div>
                <div class="right">
                  <span v-if="index != 0 || timeIntervalList.length > 1" class="delete"
                        @click="deleteTime(index)">删除</span>
                </div>
              </div>
              <div class="select-time-period">
                <div class="time-range-wrapper">
                  <div class="time-tips">请选择日期</div>
                  <div class="picker">
                    <div :style="{'background-color':(itemw.chose ? '#7accc1' : '#ccc')}"
                         v-for="(itemw,indexw) in item.week" :key="indexw" @click="itemw.chose = !itemw.chose"
                         class="picker-item item fs12"><span>{{ itemw.name }}</span></div>
                  </div>
                  <div class="time-wrapper" @click="$refs.addTime.$show(index,'timeIntervalList')">
                    <div class="time-tips">请选择时间段</div>
                    <div class="time-box">
                      <span>从</span>
                      <span class="time">
                          {{ item.startTime || '00:00' }}
                    </span>
                      <span>至</span>
                      <span class="time">{{ item.endTime || '00:00' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding: 10px 12px;">
                <van-field
                    v-model="item.words"
                    rows="2"
                    :autosize="{maxHeight:300,minHeight:150}"
                    type="textarea"
                    maxlength="1000"
                    placeholder="请输入"
                    show-word-limit
                    style="background: #f8f9fb;"
                >
                  <template #button>
                    <div style="display: none" class="button">
                      插入客户昵称
                    </div>
                  </template>
                </van-field>
                <div class="file-list" v-for="(items,indexs) in item.mediumId" :key="indexs">
                  <div class="item">
                    <div>
                      <a-icon @click="removeFile({
                      index,
                      indexs
                      })" type="minus-circle"/>
                      <span>{{ items.type }}{{ items.title }}</span>
                    </div>
                    <a-icon type="form" @click="editFile(items,{
                    index,
                    indexs
                  })"/>
                  </div>
                </div>
                <van-popover
                    class="prop"
                    v-model="item.showPopover"
                    trigger="click"
                    placement="top-start"
                >
                  <ul class="options">
                    <li @click="choseImg(true,index)" class="clickable">
                      <img
                          src="../../assets/images/multiSendabPicture.png">
                      <span>图片</span></li>
                    <li @click="choseUrl(true,index)" class="clickable"><img
                        src="../../assets/images/multiSendableLink.png">
                      <span>链接</span></li>
                    <li @click="choseApplet(true,index)" class="clickable"><img
                        src="../../assets/images/multiSendableWxapp.png">
                      <span>小程序</span></li>
                    <li @click="choseLuckDraw(true,index)" class="clickable"><img
                        src="../../assets/images/multisendablechoujiang.png">
                      <span>抽奖活动</span></li>
                  </ul>
                  <template #reference>
                    <div class="init-trigger fs14">
                      <div class="icon">
                        <img src="../../assets/images/plus-new.svg"
                             class="icon-plus-new svg-icon"></div>
                      从素材中心选择
                    </div>
                  </template>
                </van-popover>
              </div>
            </div>
          </div>

          <div v-if="timeInterval" class="add-time-phased-welcome">
            <div class="box" @click="timeIntervalList.push({
          words: '',
          week: [
            {
              name: '日',
              id: 0,
              chose: false
            },
            {
              name: '一',
              id: 1,
              chose: false
            },
            {
              name: '二',
              id: 2,
              chose: false
            },
            {
              name: '三',
              id: 3,
              chose: false
            },
            {
              name: '四',
              id: 4,
              chose: false
            },
            {
              name: '五',
              id: 5,
              chose: false
            },
            {
              name: '六',
              id: 6,
              chose: false
            }
          ],
          startTime: '',
          endTime: '',
          mediumId: [],
          showPopover: false
        })">
              <img src="../../assets/images/add-icon.svg"/>
              <span>添加分时段欢迎语</span>
            </div>
          </div>

        </div>

      </div>

      <div style="padding-bottom: 20px" @click="buildText">
        <van-button :loading="loading" type="info" style="margin: 0 auto;margin-top: 40px;width: 90%;" block>新建
        </van-button>
      </div>
    </div>

    <!--  分组  -->
    <grouping ref="grouping" @change="userGroup"/>
    <!--  员工  -->
    <employeeIndex ref="employee" @change="userChange"/>
    <!--  标签  -->
    <customerLabel ref="customerLabel" @change="userTagsChose"/>
    <!--  时间周期选择  -->
    <cycleTime ref="cycleTime" @change="weekChose"/>
    <!--  时间选择  -->
    <addTime ref="addTime" @change="timeChose"/>
    <!--  欢迎语添加  -->
    <addImage ref="addImage" @change="addImg"/>
    <!--  链接添加  -->
    <addUrl ref="addUrl" @change="addUrl"/>
    <!--  添加小程序  -->
    <addApplet ref="addApplet" @change="addApplet"/>
    <!--  添加抽奖活动  -->
    <addLuckDraw ref="addLuckDraw" @change="addLuckDraw"/>
  </div>
</template>

<script>
import grouping from "../channelCode/components/grouping";
import employeeIndex from "../channelCode/components/employeeIndex";
import customerLabel from "../channelCode/components/customerLabel";
import cycleTime from "../channelCode/components/cycleTime";
import addTime from "../channelCode/components/addTime";
import addImage from "./components/addImage";
import addUrl from "./components/addUrl";
import addApplet from "./components/addApplet";
import {greetingDe, greetingDeUpdate, greetingS, upload} from "../../api/contactWelcome"
import addLuckDraw from "./components/addLuckDraw";

export default {
  components: {
    grouping,
    employeeIndex,
    customerLabel,
    cycleTime,
    addTime,
    addImage,
    addUrl,
    addApplet,
    addLuckDraw
  },
  data() {
    return {
      // 文字
      // 新建login
      loading: false,
      // tbas切换
      tabsChose: 0,
      showH: false,
      actionsH: [{name: '固定欢迎语'}, {name: '默认欢迎语'}, {name: '不发生欢迎语'}],
      greeting: '固定欢迎语',

      // 新修改
      // 二维码名称
      message: '',
      // 选择分组
      choseProup: {
        name: '请选择'
      },
      // 选择员工客服
      radio: '1',
      // 请选择成员
      serviceList: [],
      // 员工添加上限
      upperLimit: false,
      serchUserValue: '',
      upperStepper: [],
      upperSteppert: [],
      upperResult: [],
      allRadio: [],
      //允许员工上下线
      Offline: false,
      // 备用员工
      StandbyStaffList: [],
      // 客户标签
      customerLabel: false,
      showPopover2: true,
      tagsValue: [],
      tagsText: '',
      //客户备注
      customerComments: false,
      positionValue: "",
      // 客户描述
      customerDescription: false,
      // 自动上下线
      autoSxs: [{
        user: [],
        workWeek: [],
        workWeekName: '',
        goTime: '请选择'
      }],

      // 固定欢迎语言
      fixedMessage: '',
      // 附件选择
      showPopover: false,
      // 欢迎语文件
      fixedFileList: [],
      // 分时段欢迎语
      timeIntervalList: [
        {
          words: '',
          week: [
            {
              name: '日',
              id: 0,
              chose: false
            },
            {
              name: '一',
              id: 1,
              chose: false
            },
            {
              name: '二',
              id: 2,
              chose: false
            },
            {
              name: '三',
              id: 3,
              chose: false
            },
            {
              name: '四',
              id: 4,
              chose: false
            },
            {
              name: '五',
              id: 5,
              chose: false
            },
            {
              name: '六',
              id: 6,
              chose: false
            }
          ],
          startTime: '',
          endTime: '',
          mediumId: [],
          showPopover: false
        }
      ],

      // 二维码预览
      fileList: [],
      fileImage: '../../assets/images/codeImage.png',
      // 欢迎语屏蔽
      shield: false,
      shieldList: [],
      shieldText: '',
      shieldShow: false,
      // 客户添加上限操作
      maxShow: false,
      maxText: 0,
      // 自动通过好友
      autoGo: false,
      customerMessage: '',
      nameRadio: '1',
      checked: false,
      timeInterval: false
    }
  },
  watch: {
    radio() {
      this.upperLimit = false
    }
  },
  created() {
   if (this.$route.query.id){
     this.greetingId = this.$route.query.id
     this.details()
   }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onCancelH(e) {
      this.showH = false
    },
    onSelectH(e) {
      this.greeting = e.name
    },
    // 提示
    // 员工添加上限
    upperLimitText() {
      if (this.serviceList.length <= 0 && this.upperSteppert.length <= 0) {
        this.$message.warning('未添加使用成员，请先选择使用成员')
      } else {
        this.upperLimit = true
      }
    },

    // 员工客服切换
    userYeGp() {
      this.upperLimit = false
    },
    // 员工操作max
    userMax() {
      if (this.upperResult.length <= 0) {
        this.$message.warning('请选择员工')
      } else {
        this.maxShow = true
      }
      this.maxShow = false
    },
    userEditMax() {
      if (this.radio == '1') {
        this.upperResult.map(item => {
          item.stepper = Number(this.maxText)
        })
        this.upperStepper = Array.from(new Set(this.upperSteppert.concat(this.upperResult)))

      } else {
        this.upperResult.map(item => {
          item.stepper = Number(this.maxText)
        })
        this.upperSteppert = Array.from(new Set(this.upperSteppert.concat(this.upperResult)))
      }
    },
    // 选择分组
    userGroup(data) {
      this.choseProup = data
    },

    // 选择员工
    userChange(data, info) {
      if (info.name == "serviceList") {
        this.serviceList = data
        const value = [...data]
        data.forEach(item => {
          item.stepper = 1
        })
        this.upperStepper = value
      } else if (info.name == "StandbyStaffList") {
        this.StandbyStaffList = data
      } else if (typeof info.name == 'number') {
        this.autoSxs[info.name].user = data
        const value = [...data]
        data.forEach(item => {
          item.stepper = 1
        })
        this.upperSteppert.push(...value)
      }
    },

    // 全选员工添加上限
    allUpperLimit() {
      if (this.allRadio.length > 0) {
        this.$refs.checkUpperGroup.toggleAll(true);
      } else {
        this.$refs.checkUpperGroup.toggleAll();
      }
    },

    // 添加其他工作周期
    addAutoSxs() {
      this.autoSxs.push({
        user: [],
        workWeek: [],
        workWeekName: '',
        goTime: '请选择'
      })
    },

    // 客户标签
    userTagsChose(data) {
      this.tagsValue = data
      this.tagsText = String(data.map(item => {
        return item.name
      }))
    },

    // 时间周期选择
    weekChose(data, info) {
      this.autoSxs[info.name].workWeek = data
      this.autoSxs[info.name].workWeekName = String(data.map(item => {
        return item.name
      }))

    },

    // 欢迎语部分
    timeChose(data) {
      if (data.name == 'autoSxs') {
        this.autoSxs[data.index].goTime = data.time
      } else if (data.name == 'timeIntervalList') {
        this.timeIntervalList[data.index].startTime = data.time.split("-")[0]
        this.timeIntervalList[data.index].endTime = data.time.split("-")[1]
      }
    },
    // 删除分时段
    deleteTime(index) {
      if (index == 0) {
        this.timeIntervalList.splice(index, 1)
      } else {
        this.timeIntervalList.splice(index, 1)
      }
    },

    // 添加 删除 修改 图片
    choseImg(e, i) {
      if (e) {
        this.$refs.addImage.$show({name: 'timeIntervalList', edit: false, index: i})
        this.timeIntervalList[i].showPopover = false
      } else {
        this.$refs.addImage.$show({name: 'fixedFileList', edit: false})
        this.showPopover = false
      }
    },
    addImg(data) {
      if (!data.data.edit) {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList.push(data.content)
        } else {
          this.timeIntervalList[data.data.index].mediumId.push(data.content)
        }
      } else {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList[data.data.index] = data.content
        } else {
          this.timeIntervalList[data.data.index.index].mediumId[data.data.index.indexs] = data.content
        }
      }
    },

    choseUrl(e, i) {
      if (e) {
        this.$refs.addUrl.$show({name: 'timeIntervalList', edit: false, index: i})
        this.timeIntervalList[i].showPopover = false
      } else {
        this.$refs.addUrl.$show({name: 'fixedFileList', edit: false})
        this.showPopover = false
      }
    },
    addUrl(data) {
      if (!data.data.edit) {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList.push(data.content)
        } else {
          this.timeIntervalList[data.data.index].mediumId.push(data.content)
        }
      } else {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList[data.data.index] = data.content
        } else {
          this.timeIntervalList[data.data.index.index].mediumId[data.data.index.indexs] = data.content
        }
      }
    },

    choseLuckDraw(e, i) {
      if (e) {
        this.$refs.addLuckDraw.$show({name: 'timeIntervalList', edit: false, index: i})
        this.timeIntervalList[i].showPopover = false
      } else {
        this.$refs.addLuckDraw.$show({name: 'fixedFileList', edit: false})
        this.showPopover = false
      }
    },
    addLuckDraw(data) {
      if (!data.data.edit) {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList.push(data.content)
        } else {
          this.timeIntervalList[data.data.index].mediumId.push(data.content)
        }
      } else {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList.splice(data.data.index, 1, data.content)
        } else {
          this.timeIntervalList[data.data.index.index].mediumId.splice(data.data.index.indexs, 1, data.content)
        }
      }
    },

    choseApplet(e, i) {
      if (e) {
        this.$refs.addApplet.$show({name: 'timeIntervalList', edit: false, index: i})
        this.timeIntervalList[i].showPopover = false
      } else {
        this.$refs.addApplet.$show({name: 'fixedFileList', edit: false})
        this.showPopover = false
      }
    },
    addApplet(data) {
      if (!data.data.edit) {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList.push(data.content)
        } else {
          this.timeIntervalList[data.data.index].mediumId.push(data.content)
        }
      } else {
        if (data.data.name == 'fixedFileList') {
          this.fixedFileList[data.data.index] = data.content
        } else {
          this.timeIntervalList[data.data.index.index].mediumId[data.data.index.indexs] = data.content
        }
      }
    },

    removeFile(index) {
      if (typeof index == 'number') {
        this.fixedFileList.splice(index, 1)
      } else {
        (this.timeIntervalList[index.index].mediumId[index.indexs])
        this.timeIntervalList[index.index].mediumId.splice(index.indexs, 1)
      }
    },
    editFile(item, index) {
      if (typeof index == 'number') {
        switch (item.type) {
          case '【图片】：':
            this.$refs.addImage.$show({
              name: 'fixedFileList',
              content: item,
              index,
              edit: true
            })
            break;
          case '【链接】：':
            this.$refs.addUrl.$show({
              name: 'fixedFileList',
              content: item,
              index,
              edit: true
            })
            break;
          case '【小程序】：':
            this.$refs.addApplet.$show({
              name: 'fixedFileList',
              content: item,
              index,
              edit: true
            })
            break;
          case '【抽奖】：':
            this.$refs.addLuckDraw.$show({
              name: 'fixedFileList',
              content: item,
              index,
              edit: true
            })
            break;

        }
      } else {
        switch (item.type) {
          case '【图片】：':
            this.$refs.addImage.$show({
              name: 'timeIntervalList',
              content: item,
              index: index,
              edit: true
            })
            break;
          case '【链接】：':
            this.$refs.addUrl.$show({
              name: 'timeIntervalList',
              content: item,
              index: index,
              edit: true
            })
            break;
          case '【小程序】：':
            this.$refs.addApplet.$show({
              name: 'timeIntervalList',
              content: item,
              index: index,
              edit: true
            })
            break;
          case '【抽奖】：':
            this.$refs.addLuckDraw.$show({
              name: 'timeIntervalList',
              content: item,
              index: index,
              edit: true
            })
            break;
        }
      }
    },


    // 头像预览
    // 文件大小
    onOversize(file) {
      this.$message.warning('文件大小不能超过 2MB');
    },
    // 上传文件
    afterRead(file) {
      var formData = new FormData();
      formData.append('file', file.file);
      upload(formData).then(res => {
        // this.items.title = res.data.name
        this.fileList = []
        this.fileImage = res.data.fullPath
      })
    },

    // 添加屏蔽标签
    shieldAdd() {
      this.shieldList.push(this.shieldText)
      this.shieldShow = false
      this.shieldText = ''
    },
    weekChoses (is){
      const week = [
        {
          name: '日',
          id: 0,
          chose: false
        },
        {
          name: '一',
          id: 1,
          chose: false
        },
        {
          name: '二',
          id: 2,
          chose: false
        },
        {
          name: '三',
          id: 3,
          chose: false
        },
        {
          name: '四',
          id: 4,
          chose: false
        },
        {
          name: '五',
          id: 5,
          chose: false
        },
        {
          name: '六',
          id: 6,
          chose: false
        }
      ]
      for(let i = 0; i < is.length; i++){
        for(let k= 0;k < week.length; k++){
          if(is[i] == week[k].id){
            week[k].chose = true
          }
        }
      }
      return week
    },
    ment(i){
      if(i.TextTitle){
        return {
          id: i.id,
          title: i.TextTitle,
          description: i.description,
          imageLink: i.imageLink,
          types: 1,
          type: '【链接】：',
          fileList: [{
            url:  i.imageFullPath,
            path: i.imagePath
          }]
        }
      }else if(i.description){
        return {
          id: i.id,
          title: i.name,
          description: i.description,
          name: i.name,
          type: '【抽奖】：',
          types: 2
        }
      }else if (i.appid){
        return {
          id: i.id,
          title: i.title,
          description: i.appid,
          imageLink: i.page,
          fileList: [{
            url:  i.imageFullPath,
            path: i.imagePath
          }],
          types: 1,
          type: '【小程序】：',
        }
      }else if(i.imageName && ! i.appid){
        return {
          id: i.id,
          title: i.title,
          fileList: [{
            url:  i.imageFullPath,
            path: i.imagePath
          }],
          types: 1,
          type: '【图片】：',
        }
      }
    },

    // 详情数据
    details(){
      greetingDe({greetingId: this.greetingId}).then(res=>{
        this.serviceList = res.data.employees
        this.fixedFileList = res.data.mediumContent.map(i=>{
          return this.ment(i)
        })
        this.fixedMessage = res.data.words
        this.timeInterval = res.data.spanWelcomeStatus == 1 ? true : false
        this.timeIntervalList = res.data.spanWelcome.map(i=>{
            return{
              words: i.words,
              week: this.weekChoses(i.week),
              startTime: i.startTime,
              endTime: i.endTime,
              mediumId: i.mediumContent.map(is=>{
                return this.ment(is)
              })
            }

        })
      })
    },
    // 新建数据切分
    buildData() {
      const serviceId = this.serviceList.map(item => {
        return item.id
      })

      const mediumId = this.fixedFileList.map(item => {
        return {
          type: item.types,
          id: item.id
        }
      })
      const spanWelcome = this.timeIntervalList.map(item => {
        return {
          words: item.words,
          week: Array.from(new Set(item.week.map(items => {
            if (items.chose) {
              return items.id
            }
          }))).filter(i => i),
          startTime: item.startTime,
          endTime: item.endTime,
          mediumId: item.mediumId.map(items => {
            return {
              type: items.types,
              id: items.id
            }
          })
        }
      })
      var type = null
      const spanWelcomeStatus = this.timeInterval ? 1 : 0
      const welcomeMessage = {
        words: this.fixedMessage,
        mediumId: mediumId,
      }

      return {
        serviceId,
        mediumId,
        spanWelcome,
        welcomeMessage,
        type,
        spanWelcomeStatus,
      }
    },
    // 新建
    buildText() {
      const datab = this.buildData()
      const data = {
        employees: datab.serviceId,
        spanWelcomeStatus: datab.spanWelcomeStatus,
        spanWelcome: datab.spanWelcome,
        type: '-1-',
        words: datab.welcomeMessage.words,
        mediumId: datab.welcomeMessage.mediumId,
        rangeType: 2
      }
      let error = null
      if(this.spanWelcomeStatus){
        datab.spanWelcome.forEach(i=>{
          (i)
          if(i.week.length <= 0 || !i.startTime || !i.words || !i.endTime){
            this.$message.error('请填写必填关键信息1')
            error = true
          }
        })
      }
      const inspect = {
        employees: datab.serviceId.length > 0 ? true : false,
        words: datab.welcomeMessage.words,
      }
      if(error){
        return
      }
      for (let key in inspect) {
        if (!inspect[key]) {
          this.$message.error('请填写必填关键信息')
          return
          break
        }
        // foo, bar
      }
      this.loading = true
      if(this.greetingId){
        data.greetingId = this.greetingId
        greetingDeUpdate(data).then(res=>{
          this.loading =false
          this.$message.success('修改成功')
          this.$router.back()
        })
      }else{
        greetingS(data).then(res=>{
          this.loading =false
          this.$message.success('创建成功')
          this.$router.back()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-switch__node {
  top: -1.98px
}

///deep/ .van-field__button {
//  position: absolute;
//  bottom: -5px;
//  font-size: 12px;
//  border: 1px solid #1890ff;
//  padding: 0;
//  color: #1890ff;
//  background: #ededfb;
//  padding: 1px 12px;
//  border-radius: 18px;
//  transform: scale(.8);
//  left: -14px;
//}

/deep/ .van-cell__title {
  display: contents;
}

.van-popover__content {
  .options {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    opacity: 1;
    z-index: 10;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 25%);
    border-radius: 2px;
    padding: 6px 0;

    .clickable {
      padding: 16px 24px 16px 21px;
      word-break: keep-all;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .bettown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    &:nth-child(1) {
      margin: 0;
    }
  }

  .content {
    background: #fbfbfb;
    min-height: 92%;
    //padding-top: 5px;
    //padding: 7px 10px;
    img {
      width: 22px;
      height: 22px;
    }

    .file-list {
      background: rgb(248, 249, 251);

      .item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        justify-content: space-between;

        div:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          span {
            margin-top: -3px;
            display: block;
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .user-list {
      display: flex;
      align-items: center;
      justify-content: end;
      height: 100%;
      width: 200px;
      float: right;
      /* white-space: nowrap; */
      /* text-overflow: ellipsis; */
      /* overflow: hidden; */
      white-space: nowrap;
      overflow: auto;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 2px;
        font-size: 12px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .titles {
      padding: 5px 15px;
      font-size: 15px;
      color: #444;
      font-weight: bold;
      border-bottom: 7px solid #f2f4f8;
    }

    .text-edit {
      /deep/ .van-field__word-limit {
        position: absolute;
        top: -23px;
        right: 0;
      }

      /deep/ .van-cell--required {
        display: flex;
        flex-direction: column;
      }

      /deep/ .van-field__body {
        margin-top: 10px;
      }

      /deep/ .van-cell__title {
        color: #323233;
      }
    }

    .contentss {
      padding: 10px 12px;
    }

    .openOr {
      span {
        margin-left: 3px;
        font-size: 12px;
      }
    }

    .upper-limit {
      margin: 10px 12px;
      border: 1px solid #eee;
      border-radius: 7px;
      overflow: hidden;

      .div1 {
        padding: 10px 13px;
        background: #fbfbfb;
      }

      .div2 {
        padding: 10px 13px;
        background: #fff;
      }
    }

    .init-trigger {
      display: flex;
      align-items: center;
      height: 33px;
      color: #000;
      cursor: pointer;
      margin-top: 16px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
      }
    }

    .ttips {
      /deep/ .van-icon {
        font-size: 14px !important;
        color: #1890ff !important;
        margin-right: 5px !important;
      }

      font-size: 12px;
      padding: 5px 14px;
      background: #effaff;
      margin: 7px 7px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      background: #f2f4f8;

      .left {
        height: 100%;
        display: inline-block;
        vertical-align: middle;

        .title {
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          color: #999;
          font-size: 12px;
          font-weight: 500;
          margin-right: 12px;

          .preview-icon {
            display: inline-block;
            vertical-align: middle;
            width: 1em;
            height: 1em;
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
          }
        }

        .preview {
          height: 100%;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          color: #294ba3;
          font-size: 12px;
          margin-left: 4px;
        }
      }

      .right {
        color: #294ba3;
        font-size: 12px;

        .delete {
          cursor: pointer;
        }
      }
    }

    .select-time-period {
      padding: 0 16px;

      .time-range-wrapper {
        .time-tips {
          padding-top: 16px;
          padding-bottom: 16px;
          line-height: 18px;
          color: #8c8c8c;
          font-size: 13px;
        }

        .picker {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .picker-item {
            border-radius: 50%;
            background-color: #7accc1;
            color: #fff;
          }

          .item {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 31px;
            height: 31px;
            //color: #545454;
          }
        }

        .time-wrapper {
          color: #545454;
          font-size: 14px;

          .time-tips {
            padding-top: 16px;
            padding-bottom: 16px;
            line-height: 18px;
            color: #8c8c8c;
            font-size: 13px;
          }

          .time-box {
            padding: 0 0 16px;

            .time {
              color: #000;
            }
          }
        }
      }
    }

    .add-time-phased-welcome {
      padding: 8px 0;
      background-color: #f2f4f8;

      .box {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 16px;
        background-color: #fff;

        img {
          width: 15px;
          height: 15px;
        }

        span {
          color: #294ba3;
          font-size: 14px;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
